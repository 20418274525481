var token = $('meta[name="csrf-token"]').attr('content');

$('#language-select').change(function(){
    $.ajax({
        url: "/languageSelect",
        type: 'PUT',
        data: {locale: $(this).val()},
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token);
        },
        success: function (reply) {
            location.reload()
        },
        error: function (xhr) {
            console.log('xhr', xhr);
        }
    });
})